import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: []  // Array to hold cart items
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.slug === newItem.slug && item.type === newItem.type);

      if (existingItem) {
        existingItem.quantity += newItem.quantity; // Just update quantity if the item exists
      } else {
        state.items.push(newItem);  // Add new item to the cart
      }
    },
    removeItem: (state, action) => {
      const { slug, type } = action.payload;
      state.items = state.items.filter(item => !(item.slug === slug && item.type === type));  // Remove item by slug and type
    },
    clearCart: (state) => {
      state.items = [];  // Clear all items from the cart
    }
  }
});

export const { addItem, removeItem, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
