import { Outlet } from "react-router-dom"
import { Header } from "../../Components/CommonComponents/Header/Header"
import Footer from "../../Components/CommonComponents/Footer/Footer"

const PrimaryLayout = () => {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default PrimaryLayout