export const DetoxDrinksItems = [
  {
    name: "Detox Drink (For Dite People)",
    slug: "detox-drink",
    price: 750,
    category: "bar-detox-drinks",
    description: "Detox Drink (For Dite People)",
    image: require("../../../Images/Detox water.jpg"),
    types: [
      { name: "Green Apple", price: 750 },
      { name: "Orange", price: 750 },
      { name: "Carrot", price: 750 },
      { name: "Beat Root", price: 750 },
      { name: "Fresh Lemon", price: 750 },
      { name: "Ginger", price: 750 },
      { name: "Black Salt", price: 750 },
    ],
  },
];
