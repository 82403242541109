import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import TikTokLogo from "../../../Images/tiktok.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer text-light text-center">
      <Container>
        <div className="footer-parent">
          <div className="top-part">
            <Row>
              <Col xl={3} md={6} className="mb-xl-0 mb-3">
                <div className="footer-column">
                  <Link
                    to="/"
                    className="img-div d-flex justify-content-center"
                  >
                    <img
                      src={require("../../../Images/Artboard-1-1.png")}
                      alt="Peason Specter Litt Logo"
                    />
                  </Link>
                </div>
              </Col>
              <Col xl={3} md={6} className="mb-xl-0 mb-3">
                <div className="footer-column">
                  <h4>MAKE A RESERVATION</h4>
                  <p>Your table reservation awaits you</p>
                  <a className="phoneNo" href="tel:+923001791795">
                    03001791795
                  </a>
                </div>
              </Col>
              <Col xl={3} md={6} className="mb-xl-0 mb-3">
                <div className="footer-column">
                  <h4>OPENING HOURS</h4>
                  <p>
                    Monday --- Saturday: 12:30 -- 00:00 Sunday: 13:30 -- 00:00
                  </p>
                </div>
              </Col>
              <Col xl={3} md={6} className="mb-xl-0 mb-3">
                <div className="footer-column">
                  <h4>SOCIAL MEDIA</h4>
                  <ul className="p-0 d-flex justify-content-center gap-2">
                    <li className="social-logo-parent">
                      <a
                        href="https://www.facebook.com/PearsonSpecterLitt.Cafe"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../../Images/facebookLogo.png")}
                          alt="Facebook Logo"
                        />
                      </a>
                    </li>
                    <li className="social-logo-parent">
                      <a
                        href="https://www.tiktok.com/@pearson.specter.litt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={TikTokLogo} alt="Tiktok Logo" />
                      </a>
                    </li>
                    <li className="social-logo-parent">
                      <a
                        href="https://www.instagram.com/pearsonspecterlitt.cafe"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require("../../../Images/instagramLogo.png")}
                          alt="Instagram Logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="bottom-part">
            <p className="m-0 pb-1">
              Pearson Specter Litt Cafe. &copy;  2024.  All Rights Reserved
              - Developed By 
              <a href="https://mfbzone.com/" className="text-light">
                MF Bzone
              </a>
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
