export const IceTeaItems = [
  {
    name: "Peach",
    slug: "peach",
    price: 495,
    category: "bar-ice-tea",
    description: "Peach",
    image: require("../../../Images/peach.jpg"),
    types: [],
  },
  {
    name: "Pine Apple",
    slug: "pine-apple",
    price: 495,
    category: "bar-ice-tea",
    description: "Pine Apple",
    image: require("../../../Images/pine-apple.webp"),
    types: [],
  },
  {
    name: "Berries",
    slug: "berries",
    price: 495,
    category: "bar-ice-tea",
    description: "Berries",
    image: require("../../../Images/berries.webp"),
    types: [],
  },
  {
    name: "Strawberry",
    slug: "strawberry",
    price: 495,
    category: "bar-ice-tea",
    description:
      "Strawberry Ice Tea",
    image: require("../../../Images/strawberry.webp"),
    types: [],
  },
];
