export const SoftDrinksItems = [
  {
    name: "Coke",
    slug: "coke",
    price: 275,
    category: "bar-soft-drinks",
    description: "Coke",
    image: require("../../../Images/coke.jpg"),
    types: [],
  },
  {
    name: "Diet Coke",
    slug: "diet-coke",
    price: 275,
    category: "bar-soft-drinks",
    description: "Diet Coke",
    image: require("../../../Images/diet-coke.jpeg"),
    types: [],
  },
  {
    name: "Sprite",
    slug: "sprite",
    price: 275,
    category: "bar-soft-drinks",
    description: "Sprite",
    image: require("../../../Images/sprite.jpg"),
    types: [],
  },
  {
    name: "Diet Sprite",
    slug: "diet-sprite",
    price: 275,
    category: "bar-soft-drinks",
    description: "Diet Sprite",
    image: require("../../../Images/diet sprite.jpg"),
    types: [],
  },
  {
    name: "Fanta",
    slug: "fanta",
    price: 275,
    category: "bar-soft-drinks",
    description: "Fanta",
    image: require("../../../Images/Fanta.jpg"),
    types: [],
  },
  {
    name: "Red Bull",
    slug: "red-bull",
    price: 275,
    category: "bar-soft-drinks",
    description: "Red Bull",
    image: require("../../../Images/Red bull.jpg"),
    types: [],
  },
  {
    name: "Diet Red Bull",
    slug: "diet-red-bull",
    price: 275,
    category: "bar-soft-drinks",
    description: "Diet Red Bull",
    image: require("../../../Images/diet Red bull.jpg"),
    types: [],
  },
  {
    name: "Mineral Water (Small)",
    slug: "mineral-water-small",
    price: 275,
    category: "bar-soft-drinks",
    description: "Mineral Water (Small)",
    image: require("../../../Images/minal water.jpg"),
    types: [],
  },
  {
    name: "Mineral Water (Large)",
    slug: "mineral-water-large",
    price: 275,
    category: "bar-soft-drinks",
    description: "Mineral Water (Large)",
    image: require("../../../Images/large mineral water.jpg"),
    types: [],
  },
];
