export const MahjitoMocktalItems = [
  {
    name: "Kiwi Mahjito",
    slug: "kisi-mahjito",
    price: 790,
    category: "bar-mahjito-mocktal",
    description:
      "Kiwi Flaver, Fresh Lemon Juice, Fresh Mint Syrup, Mint Leaves, Lemon Slices, IceCube, Soda",
    image: require("../../../Images/kiwi-mahjito.webp"),
    types: [],
  },
  {
    name: "Passion Mahjito",
    slug: "passion-mahjito",
    price: 790,
    category: "bar-mahjito-mocktal",
    description:
      "Passion Fruit Flaver, Fresh Lemon Juice, Fresh Mint Syrup, Mint Leaves, Lemon Slice, Ice Cube Soda.",
    image: require("../../../Images/passion-mahjito.jpg"),
    types: [],
  },
  {
    name: "Peach Mohjito",
    slug: "peach-mohjito",
    price: 790,
    category: "bar-mahjito-mocktal",
    description:
      "Peach Flaver, Fresh Lemon Juice, Fresh Mint Syrup, Lemon Slices, Mint Leaves, Ice Cube, Soda.",
    image: require("../../../Images/peach-mahjito.jpg"),
    types: [],
  },
  {
    name: "Strawberry Mahjito",
    slug: "strawberry-mahjito",
    price: 790,
    category: "bar-mahjito-mocktal",
    description:
      "Strawberry Flaver, Fresh Lemon Juice, Fresh Mintsyrup, Lemon Slice, Mint Leaves, Ice Cube, Soda.",
    image: require("../../../Images/psl-margrita.jpg"),
    types: [],
  },
  {
    name: "Berries Mahjito",
    slug: "berries-mahjito",
    price: 790,
    category: "bar-mahjito-mocktal",
    description: "Mix Berries Flaver, Fresh Lemon Juice, Fresh Mint Syrup, Lemon Slices, Mint Leaves, Ice Cube, Soda",
    image: require("../../../Images/berries-mahjito.jpg"),
    types: [],
  },
];