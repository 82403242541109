import CategoryItemsList from "../CommonComponents/CategoryItemsList/CategoryItemsList";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BlendedMocktailItems } from "../../data/menu/bar/blended-mocktail";
import { MahjitoMocktalItems } from "../../data/menu/bar/mahjito-mocktal";
import { IceTeaItems } from "../../data/menu/bar/ice-tea";
import { SmoothiesItems } from "../../data/menu/bar/smoothies";
import { HotCoffeeItems } from "../../data/menu/bar/hot-coffee";
import { FrappeBlendedColdCoffeeItems } from "../../data/menu/bar/frappe-blended-cold-coffee";
import { WildShakeItems } from "../../data/menu/bar/wildShake";
import { ColdCoffeeItems } from "../../data/menu/bar/cold-coffee";
import "./SubCategory.css";
import { DetoxDrinksItems } from "../../data/menu/bar/detox-drinks";
import { TeaItems } from "../../data/menu/bar/tea";
import { SoftDrinksItems } from "../../data/menu/bar/soft-drinks";

const SubCategory = () => {
  const [items, setItems] = useState([]);
  const [heading, setHeading] = useState("");

  const params = useParams();
  const { category, subCategory } = params;

  const navigate = useNavigate();

  useEffect(() => {
    if (category === "bar" && subCategory === "blended-mocktail") {
      setItems(BlendedMocktailItems);
      setHeading("Blended Mocktail");
    } else if (category === "bar" && subCategory === "mahjito-mocktal") {
      setItems(MahjitoMocktalItems);
      setHeading("Mahjito Mocktal");
    } else if (category === "bar" && subCategory === "ice-tea") {
      setItems(IceTeaItems);
      setHeading("Ice Tea");
    } else if (category === "bar" && subCategory === "smoothies") {
      setItems(SmoothiesItems);
      setHeading("Smoothies");
    } else if (category === "bar" && subCategory === "hot-coffee") {
      setItems(HotCoffeeItems);
      setHeading("Hot Coffee's");
    } else if (category === "bar" && subCategory === "frappe-blended-cold-coffee") {
      setItems(FrappeBlendedColdCoffeeItems);
      setHeading("Frappe Blended (Cold Coffee)");
    } else if (category === "bar" && subCategory === "wild-shake") {
      setItems(WildShakeItems);
      setHeading("Wild Shake's (Ice Cream)");
    } else if (category === "bar" && subCategory === "cold-coffee") {
      setItems(ColdCoffeeItems);
      setHeading("Cold Coffee's (Over Ice)");
    } else if (category === "bar" && subCategory === "detox-drinks") {
      setItems(DetoxDrinksItems);
      setHeading("Detox Drinks (Keto) - For Dite People");
    } else if (category === "bar" && subCategory === "tea") {
      setItems(TeaItems);
      setHeading("Tea");
    } else if (category === "bar" && subCategory === "soft-drinks") {
      setItems(SoftDrinksItems);
      setHeading("Soft Drinks");
    } else {
      navigate("/404");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="sub-categories-page">
      <section className="menu-section">
        <Container>
          <CategoryItemsList CategoryItems={items} heading={heading} id="" />
        </Container>
      </section>
    </div>
  );
};

export default SubCategory;
