import './PrimaryRoutes.css';
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PrimaryLayout from "./PrimaryLayout/PrimaryLayout";
import SubCategory from '../Components/SubCategory/SubCategory';
const Menu = lazy(() => import("../Components/Menu/Menu"));
const Home = lazy(() => import("../Components/Home/Home"));
const About = lazy(() => import("../Components/About/About"));
const ContactUs = lazy(() => import("../Components/ContactUs/ContactUs"));
const ProductDetail = lazy(() =>
  import("../Components/ProductDetail/ProductDetail")
);
const Checkout = lazy(() => import("../Components/Checkout/Checkout"));
const NotFound = lazy(() => import('../Components/NotFound/NotFound'));

const PrimaryRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className='body'>
          <div className="rl-loading-container">
            <div className="rl-loading-thumb rl-loading-thumb-1"></div>
            <div className="rl-loading-thumb rl-loading-thumb-2"></div>
            <div className="rl-loading-thumb rl-loading-thumb-3"></div>
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<PrimaryLayout />}>
          <Route index element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/product/:category/:slug" element={<ProductDetail />} />
          <Route path='/sub-category/:category/:subCategory' element={<SubCategory />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PrimaryRoutes;