import PrimaryRoutes from "./routes/PrimaryRoutes.jsx";
import { store } from "./redux/store";
import { Provider } from 'react-redux';
import { ToastContainer } from "react-toastify";
import 'react-toastify/ReactToastify.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <ToastContainer />  
      <Provider store={store}>
        <PrimaryRoutes />
      </Provider>
    </div>
  );
}

export default App;
