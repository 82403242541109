import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CategoryItemsList.css";

const CategoryItemsList = ({ CategoryItems, id, heading }) => {
  return (
    <div className={`category-items-parent ${id}`} id={id}>
      <h3>{heading}</h3>
      <Row>
        {CategoryItems?.map((item, index) => (
          <Col key={index} lg={4} sm={6} className="mb-4">
            <Link
              to={`/product/${item?.category}/${item?.slug}`}
              className="menu-item-card text-decoration-none"
            >
              <div className="img-div d-flex justify-content-center align-items-center">
                <img src={item?.image} alt={item?.name} />
              </div>
              <h4>{item?.name}</h4>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CategoryItemsList;
